<template>

    <div>

        <div class="container-lg">
            <div class="auth-container">
                <div class="auth-form">    

                    <h2>Reset password</h2>

                    <!-- Alert messages -->
                    <Alerts :_success="success" :_error="error"></Alerts>               

                    <p>Enter the email address associated with your account and we'll send you a link to reset your password.</p>

                    <form autocomplete="off" @submit.prevent="requestResetPassword">
                        
                        <!-- Email address -->
                        <div class="form-group mb-3">
                            <label class="form-label form__label" for="user_email">Email</label>
                            <input type="email" v-model="email" class="form-control" id="" required>
                        </div>

                        <!-- Submit button -->
                        <div class="mb-3">
                            <button type="submit" :disabled="wait" class="custom-btn-lg custom-btn-secondary custom-btn-full">
                                <template v-if="wait">Please wait...</template>
                                <template v-else>Send Link</template>
                            </button>
                        </div>

                    </form><!--End password reset form -->

                    <p class="login-link-small mt-3">
                        <router-link to="/login"><font-awesome-icon icon="arrow-left" />Return to login</router-link>
                    </p>

                </div><!-- End auth-form -->

                <!-- Auth footer -->
                <div class="auth-footer">
                    <ul>
                        <li><router-link to="/">Sabasi</router-link></li>
                        <li><router-link to="/privacy-policy">Privacy Policy</router-link></li>
                        <li><router-link to="/">Terms &amp; Conditions</router-link></li>
                    </ul>
                </div>

            </div><!-- End auth-container -->

        </div><!-- End container-lg -->

    </div>
</template>

<script>
import Alerts from "../Helpers/Alerts"

export default {
    name: 'ForgotPassword',
    components: {Alerts},
    data() {
        return {
            email: "",
        }
    },
    methods: {
        requestResetPassword() {
            this.clear()
            this.wait = true
            this.$http.post("api/password/reset/email", {email: this.email}).then(() => {
                this.success = "If email exists you will get a response. Redirecting to login..."
                setTimeout(() => this.$router.push('/login') , 800)
            }).catch(error => {
                this.showError(error)
            }).then(() => {
                this.wait = false
            })
        }
    }
}
</script>